/* .list h1,  */

.list {
    margin-left: 0;
    position: relative;
    height: calc(100vh - 100px);
    overflow-y: auto;
    transition: all 0.5s;
}

.list table tbody tr th {
    padding-left: 30px;
    letter-spacing: 0.34px;
}

.list table tr th img {
    padding-left: 27px;
}

.list table tr th i {
    font-size: 15px;
    padding: 0 4px;
}

.list h1 {
    margin: 24px 0px 53px 70px;
    color: #2994D1;
    font-weight: bold;
    font-size: 40px;
    letter-spacing: 0.8px;
}

.plus-btn {
    position: fixed;
    bottom: 37px;
    right: 50px;
}

.plus-btn button {
    background: #2994D1;
    box-shadow: 2px 10px 10px #0000004D;
    padding: 20px 23px;
    border: none;
    border-radius: 50px;
}

.plus-btn button i {
    font-size: 23px;
    color: white;
    /* font-weight: lighter; */
}

.pagination {
    display: inline-block;
    position: fixed;
    bottom: 50px;
    left: 30px;
    transition: all 0.5s;
}

.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border: none;
    font-size: 12px;
    border-radius: 10px;
    background: #F4F4F4;
    margin: 0 10px;
    box-shadow: 0px 3px 6px #00000029;
    letter-spacing: 0.34px;
}

.pagination .active {
    background: #1B88C8;
    color: white;
}

.pagination .arrow {
    background: none;
    color: black;
    box-shadow: none;
}