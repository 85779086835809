.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 49px 70px;
    height: 17vh;
}

.logo img {
    width: 372px;
    height: auto;
}

.underline {
    display: flex;
    margin-top: 17px;
}

.underline .uline {
    width: 63px;
    height: 5px;
}

.input-icon-wrap,
.input-icon-wrap2 {
    display: flex;
    flex-direction: row;
    width: 451px;
    height: 66px;
}

.login-input {
    margin: 38.25px 0px 74.75px 0px;
}

.input-icon-wrap2 {
    margin-top: 14px;
}

input:focus,
button:focus {
    outline: none;
}

.input-icon {
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    background-color: #F4F4F4;
    padding: 18px 25px;
}

.input-with-icon {
    border: none;
    background-color: #F4F4F4;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    padding: 10px 25px;
    font-size: 20px;
    flex: 1;
}

.input-with-icon2 {
    flex: 1;
    border: none;
    background-color: #F4F4F4;
    padding: 10px 25px;
    font-size: 20px;
}

.input-icon2 {
    border: none;
    background-color: #F4F4F4;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    padding: 18px 25px;

}

.btn {
    width: 274px;
    height: 60px;
    border: none;
    border-radius: 15px;
    background: #2994D1;
    font-size: 20px;
    color: white;
    letter-spacing: 0.4px;
}

.btn:hover {
    color: white;

}

.login {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    flex-direction: column;
    padding-top: 75px;
}

.login h1 {
    font-size: 50px;
    letter-spacing: 1px;
}