header{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    box-shadow: 0px 2px 6px #00000026;
    position: relative;
    z-index: 1;
}
.admin{
    display: flex;
}
.admin-icon{
    padding: 0 27px;
}