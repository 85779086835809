
/* @font-face {
    font-family: FontBold;
    src: url(./assets/Fonts//Helvetica\ Neu\ Bold.ttf) format("truetype");
  }
  
  @font-face {
    font-family: FontLight;
    src: url(./assets/Fonts/HelveticaNeue\ Light.ttf) format("truetype");
  }
  @font-face {
    font-family: FontMedium;
    src: url(./assets/Fonts/HelveticaNeue\ Medium.ttf) format("truetype");
  }
  @font-face {
    font-family: FontThin;
    src: url(./assets/Fonts/HelveticaNeue\ Thin.ttf) format("truetype");
  } */

  @font-face {
    font-family: "Helvetica";
    src: url(./assets/Fonts/Helvetica\ Neu\ Bold.ttf) format("truetype");
    font-weight: Bold;
    font-style: normal;
  }
  @font-face {
    font-family: "Helvetica";
    src: url(./assets/Fonts/HelveticaNeue\ Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Helvetica";
    src: url(./assets/Fonts/HelveticaNeue\ Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Helvetica";
    src: url(./assets/Fonts/HelveticaNeue\ Thin.ttf) format("truetype");
    font-weight: 100;
    font-style: normal;
  }
  body{
    font-family: "Helvetica", sans-serif!important;
  }

.sidebar-open{
  margin-left: 325px!important;
}

input{
  font-family: "Helvetica",sans-serif!important;
  font-weight: 500;
}

  