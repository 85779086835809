#form {
    margin-left: 0;
    transition: all 0.5s;
}

.form-heading {
    display: flex;
    padding-left: 30px;
    color: #2994D1;
    padding-top: 29px;
    letter-spacing: 0.7px;
}

.form-heading span i {
    font-size: 35px;
    padding-top: 1.5px;
}

.form-heading h1 span i {
    font-size: 20px;
    font-weight: bold;
    vertical-align: middle;
    padding: 0 6px;
}

.form-heading h1 {
    padding-left: 36px;
    font-size: 35px;
    font-weight: bold;
}

.form-elements,
.value-element {
    padding-left: 97px;
    display: flex;
    flex-direction: column;
}

.heading {
    font-size: 20px;
    color: #2994D1;
    margin-top: 40px;
    letter-spacing: 0.4px;
}

.form-ele-1 {
    display: flex;
    flex-direction: column;
}

.input-1 input,
.input-2 input,
#Selector {
    padding: 12px 20px 12px 0;
    margin: 8px 33px 8px 0;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid black;
    font-size: 16px;
    width: 266px;
    letter-spacing: 0.32px;
}

#Selector:focus {
    outline: none;
}

.input-1,
.value-1,
.value-2 {
    display: flex;
}

.icon {
    padding: 10px;
    min-width: 50px;
    text-align: center;
}

.last-input {
    display: flex;
    width: 49.2%;
    justify-content: flex-end;
}

.form-btn {
    padding-left: 97px;
    margin-top: 55px;
}

.form-btn .btn {
    color: white;
    margin-right: 30px;
    letter-spacing: 0.4px;
    margin-bottom: 30px;
}

.form-btn .reset-btn {
    color: black;
    margin-right: 30px;
    letter-spacing: 0.4px;
    margin-bottom: 30px;
    background: #F4F4F4;
}