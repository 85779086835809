.main-bar{
    width: 325px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: -325px;
    transition: all 0.5s;
    z-index: 2;
    background-color: white;
    color: black;
}

.sidebar-active{
    box-shadow: 0px 2px 6px #00000026;
    left: 0;
}

#main{
    flex-shrink: 0;
}
.top-icon{
    flex-shrink: 0;
    display: flex;
    flex-shrink: 0;
    justify-content: space-evenly;
    padding-top: 32px;
}
.bottom {
    flex-grow: 1;  
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 58px;
    font-size: 15px;
    margin-left: 15px;
}
.bottom ul {
    display: flex;
    list-style: none;
    flex-direction: column;
}
.bottom ul h6{
    color: #2994D1;
    font-size: 15px;
    font-weight: bold;
}

.bottom ul li a{
    text-decoration: none;
    color: black;
    font-size: 15px;
    font-weight: bold;
}
.bottom ul li i{
    padding: 0px 10px;
}
.bottom .dropdown li a{
    font-weight: 400;
}
.dropdown{
    /* transform: translateY(-50px); */
    box-sizing: border-box;
    border-left: 1px solid white;
    /* display: none!important; */
    max-height: 0;
    overflow: hidden;
    /* opacity: 0; */
    transition: all 0.3s;
    /* justify-content: end; */
}

.dropdown-open{
    max-height: 300px;
    /* opacity: 1; */
    /* display: flex!important;
    transform: translateY(0px); */
}
/* .dropdown li{} */
li{
    display: block;
    transition-duration: 0.5s;
    float: left;
    position: relative;
    padding: 22px 0;
}
li:hover{
    cursor: pointer;
}
/* ul li ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    margin-top: 1rem;
    left: 0;
    display: none;
} */
ul li i:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
  clear: both;
  width: 100%;
}
.setting, .copyright{
    margin-left: 50px;
    margin-bottom: 30px;
}

.logout {
    margin-left: 50px;
    margin-right: 40px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #FF0000BB;
    background-color: white;
    border: 0;
    text-align: left;
    font-family: "Helvetica",sans-serif!important;
    font-weight: 500;

}
