/* .value1 {
    margin-top: 24px;
    display: flex;
}
.value1 .elem{
    display: flex;
} */
#value-page{
    margin-left: 0;
    transition: all 0.5s;
}
.value-element .value-1 p,
.value-element .value-2 p{
    padding: 12px 20px 0 0;
    margin: 8px 33px 8px 0;
    font-size: 16px;
    width: 266px;
    line-height: 1.15;
    letter-spacing: 0.32px;
}
.value-2{
    font-size: 16px;
    font-weight: bold;
}
